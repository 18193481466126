import React, { useState } from 'react'

import Velos from '../Images/Branding/Velos.png'

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen)
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light ">
      <div className="container">
        <a className="navbar-brand" href="">
          <img src={Velos} width="250" height="" />
        </a>
        <button className="navbar-toggler" type="button" onClick={toggleNav}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}>
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <a className="nav-link" href="#">
                Team
              </a>
            </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => scrollToSection('serviceProSection')}
              >
                Team
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => scrollToSection('investorSection')}
              >
                Investments
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => scrollToSection('pricingSection')}
              >
                Founders
              </a>
            </li> */}
            {/* <li className="nav-item">
							<a className="nav-link" href="#">
								Reviews
							</a>
						</li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
