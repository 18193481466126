import React from 'react'
import Elie from '../Images/Workers/Elie.jpeg'
import Ashar from '../Images/Workers/Ashar.jpeg'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Serviceproshero = () => {
  return (
    <div className="container serviceproherosection" id="serviceProSection">
      <h1 className="text-center">Our Team</h1>
      <section className="team-section py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card border-1 pt-5 my-5 position-relative">
                <div className="card-body p-4">
                  <div className="member-profile position-absolute w-100 text-center">
                    <img
                      className="rounded-circle mx-auto d-inline-block shadow-sm"
                      src={Elie}
                      alt=""
                    ></img>
                  </div>
                  <div className="card-text pt-1">
                    <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                      Elie Nabushosi
                    </h5>
                    <div className="mb-3 text-center">
                      Co-founder / Managing Partner
                    </div>
                    <div>
                      Elie earned his MBA from Southern Methodist University,
                      where he also played Divison I Football. His background is
                      in business, product management & management consulting
                      for Fortune 500 companies like Google & Meta while at
                      Accenture. His expertises are in product management,
                      business operations optimization, & Software Engineering.
                      He is also currently serving as a Senior Product Manager
                      at Nerdwallet. Elie enjoys running, cooking & traveling.
                      He was born in the Congo, grew up in Dallas, but now
                      resides in San Francisco, CA.
                    </div>
                  </div>
                </div>
                <div className="card-footer theme-bg-primary border-0 text-center">
                  <ul className="social-list list-inline mb-0 mx-auto">
                    <li className="list-inline-item">
                      <a
                        className="text-dark"
                        href="mailto:elie@velos.ventures"
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="text-dark"
                        href="https://www.linkedin.com/in/elie-nabushosi-mba-65308910a/"
                      >
                        <svg
                          className="svg-inline--fa fa-linkedin-in fa-w-14 fa-fw"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="linkedin-in"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          ></path>
                        </svg>{' '}
                        <i className="fab fa-linkedin-in fa-fw"></i>{' '}
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="text-dark"
                        href="https://twitter.com/elielondon7"
                      >
                        <svg
                          className="svg-inline--fa fa-twitter fa-w-16 fa-fw"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="twitter"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                          ></path>
                        </svg>{' '}
                        <i className="fab fa-twitter fa-fw"></i>{' '}
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="text-dark"
                        href="https://www.instagram.com/elielondon7/"
                      >
                        <svg
                          className="svg-inline--fa fa-instagram fa-w-14 fa-fw"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="instagram"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                          ></path>
                        </svg>{' '}
                        <i className="fab fa-instagram fa-fw"></i>{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card border-1 pt-5 my-5 position-relative">
                <div className="card-body p-4">
                  <div className="member-profile position-absolute w-100 text-center">
                    <img
                      className="rounded-circle mx-auto d-inline-block shadow-sm"
                      src={Ashar}
                      alt=""
                    ></img>
                  </div>
                  <div className="card-text pt-1">
                    <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                      Ashar Kandathil
                    </h5>
                    <div className="mb-3 text-center">
                      Co-founder / Managing Partner
                    </div>
                    <div>
                      Ashar, originally from Toronto, ON, is currently based in
                      San Francisco, CA. Holding a degree in Management
                      Engineering from the University of Waterloo, he has
                      transitioned from a Software Engineer role at Hive.AI to
                      his current position as an Engineer at Finch. Specializing
                      in distributed systems, data pipelining, and microservices
                      architecture, Ashar excels in crafting scalable and
                      efficient solutions. Outside of work, he indulges his
                      passions for traveling, investing, basketball, and
                      reading, constantly seeking new adventures and knowledge.
                    </div>
                  </div>
                </div>
                <div className="card-footer theme-bg-primary border-0 text-center">
                  <ul className="social-list list-inline mb-0 mx-auto">
                    <li className="list-inline-item">
                      <a
                        className="text-dark"
                        href="mailto:ashar@velos.ventures"
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="text-dark"
                        href="https://www.linkedin.com/in/ashar-kan/"
                      >
                        <svg
                          className="svg-inline--fa fa-linkedin-in fa-w-14 fa-fw"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="linkedin-in"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          ></path>
                        </svg>{' '}
                        <i className="fab fa-linkedin-in fa-fw"></i>{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Serviceproshero
