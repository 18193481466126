import React from 'react'
import Investment from '../Images/Icons/Investment.png'
import Grupa from '../Images/Icons/Grupa.png'
import PSYKHE from '../Images/Icons/PSYKHE.png'
import RYSE from '../Images/Icons/RYSE.jpeg'
import Sytn from '../Images/Icons/Sytn.png'
import Openchef from '../Images/Icons/Openchef.png'
import Spell from '../Images/Icons/Spell.png'
import Intito from '../Images/Icons/Intito.png'
import Browserbase from '../Images/Icons/Browserbase.png'

const Investorhero = () => {
  return (
    <div
      className="container serviceproviderherosection text-center"
      id="investorSection"
    >
      <h1>Our Portfolio</h1>

      <div className="feature-blocks row">
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://www.grupa.io/">
              <img className="icon" src={Grupa}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://www.grupa.io/">
              <h2 className="featureitemheader">Grupa</h2>
            </a>
          </div>
          <p>Early Stage</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://psykhe.com/">
              <img className="icon" src={PSYKHE}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://psykhe.com/">
              <h2 className="featureitemheader">PSYKHE AI</h2>
            </a>
          </div>
          <p>Early Stage</p>
          <a href="https://www.forbes.com/sites/brucerogers/2021/04/27/psykhe-uses-personality-traits-and-ai-to-build-a-better-recommendation-engine/?sh=635df1e82a77">
            <p>Seen on Forbes</p>
          </a>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://www.helloryse.com/">
              <img className="icon" src={RYSE}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://www.helloryse.com/">
              <h2 className="featureitemheader">RYSE</h2>
            </a>
          </div>
          <p>Early Stage</p>
          <p>Recently raised Series A of $2M</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://syntrillo.com/">
              <img className="icon" src={Sytn}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://syntrillo.com/">
              <h2 className="featureitemheader">SYNTRILLO</h2>
            </a>
          </div>
          <p>Early Stage</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://tryopenchefs.com/">
              <img className="icon" src={Openchef}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://tryopenchefs.com/">
              <h2 className="featureitemheader">OPENCHEFS</h2>
            </a>
          </div>
          <p>Early Stage </p>
          <a href="https://medium.com/@Hirect/ceo-insights-jeson-lee-junzhen-openmeal-openchefs-a2d23d49f394">
            <p>Seen on Medium</p>
          </a>
        </div>
      </div>
      <div className="feature-blocks row">
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://www.respell.ai/">
              <img className="icon" src={Spell}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://www.respell.ai/">
              <h2 className="featureitemheader">RESPELL</h2>
            </a>
          </div>
          <p>Early Stage</p>
          <p>Backed by Craft Ventures, $4.75M</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://www.inito.com/">
              <img className="icon" src={Intito}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://www.inito.com/">
              <h2 className="featureitemheader">Inito</h2>
            </a>
          </div>
          <p>Early Stage</p>
          <p>Recently raised a $6M Series A led by Fireside Ventures</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <a href="https://www.browserbase.com/">
              <img className="icon" src={Browserbase}></img>
            </a>
          </div>
          <div className="headersize">
            <a href="https://www.browserbase.com/">
              <h2 className="featureitemheader">Broswerbase</h2>
            </a>
          </div>
          <p>Early Stage</p>
          <p>Backed by Basecase VC, $1.8M Pre-seed</p>
        </div>
      </div>
      {/* <img className="speciality" src={manrun}></img> */}
      <img className="investment" src={Investment}></img>
      {/* <img className="escrow" src={Manpaper}></img> */}
    </div>
  )
}

export default Investorhero
