import React from 'react'
import Tiltphone from '../Images/Branding/Tiltphone.png'
import presentation from '../Images/Icons/presentation.png'
import Man from '../Images/Features/Man.avif'
import climbing from '../Images/Icons/climbing.png'

const Brandinghero = () => {
  return (
    <div className="text-center ">
      <div className="container brandingsection branding-container">
        <div className="row">
          <div className="col-md-6 heroimg">
            <h1 className="display-5 lilitaoneblack">Reaching New Heights</h1>

            <p>
              Empowering visionary founders with the resources and support to
              turn their world-changing ideas into reality. Velos Ventures
              provides not just capital, but strategic guidance and a network of
              industry experts to propel your venture forward.
            </p>
            <img className="biddocument" src={presentation}></img>

            <h2 className="lilitaonered my-5">Building the Future Together</h2>
          </div>
          <div className="col-md-6 brandingvalueprop">
            <img className="phonetilt" src={climbing} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Brandinghero
