import React from 'react'

import People from '../Images/Icons/People.png'
import Hammer from '../Images/Icons/Hammer.png'
import Handshake from '../Images/Icons/Handshake.png'
import Honest from '../Images/Icons/Honest.png'
import Longterm from '../Images/Icons/Longterm.png'
import manrun from '../Images/Icons/manrun.png'
import Manpaper from '../Images/Icons/Manpaper.png'
import Womanred from '../Images/Icons/Womanred.png'

const FeaturesHero = () => {
  return (
    <div className="container featureherosection text-center">
      <h1>How We Operate</h1>

      <div className="feature-blocks row">
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <img className="icon" src={Hammer}></img>
          </div>
          <div className="headersize">
            <h2 className="featureitemheader">Hands On Support</h2>
          </div>
          <p>Roll Our Sleeves Up & Build with You</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <img className="icon" src={People}></img>
          </div>
          <div className="headersize">
            <h2 className="featureitemheader">Our Network</h2>
          </div>
          <p>Make Intoductions to VCs, Angel, Feauture Employees. & Advisors</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <img className="icon" src={Honest}></img>
          </div>
          <div className="headersize">
            <h2 className="featureitemheader">Our Honesty</h2>
          </div>
          <p>Tell You Exactly What We Think & Why, No Fluff</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <img className="icon" src={Longterm}></img>
          </div>
          <div className="headersize">
            <h2 className="featureitemheader">Long Term Mindset</h2>
          </div>
          <p>Focus on The Future, Not Just Today</p>
        </div>
        <div className="feature col-md-4 col-sm-6">
          <div className="headersize">
            <img className="icon" src={Handshake}></img>
          </div>
          <div className="headersize">
            <h2 className="featureitemheader">Relationship Over Everything</h2>
          </div>
          <p>Help You Build Relationships With World Class People </p>
        </div>
        <img className="speciality" src={manrun}></img>
        <img className="addproperty" src={Womanred}></img>
        <img className="escrow" src={Manpaper}></img>
      </div>
    </div>
  )
}

export default FeaturesHero
