import { Helmet } from 'react-helmet'
import { HelmetProvider } from 'react-helmet-async'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './App.css'
import Header from './Corecomponent/Header'
import EmailHero from './Corecomponent/Emailhero'
import Brandinghero from './Corecomponent/Brandinghero'
import FeaturesHero from './Corecomponent/Featurehero'
import Serviceproshero from './Corecomponent/Serviceproshero'
import Investorhero from './Corecomponent/Investorhero'
import Makemoneyhero from './Corecomponent/Makemoneyhero'
import Reviews from './Corecomponent/Reviews'
import Footer2 from './Corecomponent/Footer2'

function App() {
  const helmetContext = {}
  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <Helmet>
          <title>Velos Ventures: Reaching New Heights</title>
          <meta name="description" content="Velos Ventures" />
          <meta property="og:image" content="%PUBLIC_URL%/Vlogo.png" />
        </Helmet>
        <Header />
        <Brandinghero />

        <FeaturesHero />
        <Serviceproshero />
        <Investorhero />
        {/* <Makemoneyhero /> */}

        {/* <Reviews /> */}
        <Footer2 />
      </HelmetProvider>
    </div>
  )
}

export default App
